<template>
<div style="margin: 25px; position: relative;">
    <div style="z-index: 1; position: relative; border-top: 1px solid lightgrey; top: 25px; width: 100%;"/>
    <div style="z-index: 5; position: relative; background-color: white; margin: 0 auto; padding-top: 10px; border: 1px solid lightgrey; border-radius: 50%; text-align: center; font-weight: bold; width: 50px; height: 50px;">
        <str index="authenticator.text.or" style='position:relative; top:1px;'/>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
