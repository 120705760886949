<template>
  <div style="cursor: pointer;">
    <div class="logo">
        <v-icon>
            {{icon}}
        </v-icon>
    </div>
    <div :style="{'background-color':bgColor}" class="company" v-html="text"/>
  </div>
</template>

<script>
export default {
    props: {
        bgColor: {
            type: String,
            required: false,
            default: 'white'
        },
        icon: {
            type: String,
            require: true
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.logo, .company{
    display: inline-block;
    height: 50px;
    border: 1px solid lightgrey;
    color: white;
    padding: 5px;
}
.logo{
    text-align: center;
    width: 50px;
}

.company{
    width: 200px;
}
</style>