<template>
    <div id='signInUpView' :style="{'background-image':'URL('+images.Circles+')'}">
        <div class='flex'>
            <div class='imageAndBlurb'>
                <img :src="images.Logo"/>
                <str class='str' index="sign_up.blurb"/>
            </div>
            <div class='form'>
                <SignInUp v-if="$route.name!='SignUp' || ($route.name=='SignUp' && validCode)" :prefill="prefill" :check_in_code="check_in_code"/>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '@/assets/logo.png'
import Circles from '@/components/Authorizer/assets/circles.png'
import SignInUp from '@/components/Authorizer/SignInUp'
export default {
    mounted: function(){
      this.checkIn()
    },
    components: {
        SignInUp
    },
    data: function(){
      return {
        prefill: null,
        validCode: false
      }
    },
    methods: {
      checkIn: async function(){
        let check_in_code = this.check_in_code
        if(check_in_code){
          let response = await this.sendRequest('get',`/api/check_in/${check_in_code}`)
          if(response.status==200){
            let data = response.data

            if(data && data.hash==this.check_in_code){
                this.prefill = {
                    given_name: data.invitee.given_name,
                    family_name: data.invitee.family_name,
                    email: data.email
                }
                this.validCode = true
            }else{
              alert('Invalid Invite Code')
              this.$router.push({name:'SignIn'})
            }
          }
        }
      },
    },
    computed: {
      check_in_code: function(){
        return this.$route.params.check_in_code
      },
      images: function(){
        return {
          Logo,
          Circles
        }
      },
      path: function(){
        return this.$route.path.split('/')[1]
      },
    },
    watch: {
      path: function(){
        let path = this.path
        console.log('path update',path)
        if(path=='sign_up'){
          this.checkIn()
        }
      }
    }
}
</script>

<style lang='scss'>

    #signInUpView {
        background-size: contain;
        margin-top: 48px;

        @media(max-width: 575px){
            margin-top: 0px;
        }

         > .flex {
            display: flex;
            padding: 24px 96px;

            @media(max-width: 1399px){ // xl < 1400
                padding: 24px 80px;
            }
            @media(max-width: 1199px){ // lg < 1200
                padding: 24px 32px;
            }
            @media(max-width: 991px){ // md < 992
                padding: 24px 16px;
            }
            @media(max-width: 767px){ // sm < 768
                padding: 24px 0px;
            }
            @media(max-width: 575px){ // xs < 576
                flex-flow: column;
            }

            .imageAndBlurb,
            .form {
                width: 50%;
                @media(max-width: 575px){ // xs < 576
                    width: 100%;
                }
            }

            .imageAndBlurb {
                margin-top: 66px;
                padding: 0 24px;

                @media(max-width: 1199px){
                    margin-top: 71px;
                }
                @media(max-width: 991px){ // md < 992
                    margin-top: 76px;
                }
                @media(max-width: 767px){ // sm < 768
                    padding: 0 16px;
                }
                @media(max-width: 575px){ // xs < 576
                    margin-top: 24px;
                    padding: 0 8px;
                }

                img {
                    width: 100%;
                    margin-bottom: 16px;
                }

                .str {
                    display: block;
                    margin: 0 auto;
                    padding: 0 4%;
                    text-align: left;

                    h1 {
                        font-size: 24px;
                        margin-bottom: 16px;

                        @media(max-width: 991px){
                            font-size: 20px;
                        }
                        @media(max-width: 575px){
                            visibility: hidden;
                            position: absolute;
                        }
                    }

                    h3 {
                        margin-bottom: 8px;
                    }

                    p {
                        @media(max-width: 575px){
                            font-size: 14px;
                        }
                    }
                }
            }

            .form {
                padding: 0 88px;

                @media(max-width: 1399px){ // xl < 1400
                    padding: 0 72px;
                }
                @media(max-width: 1199px){ // lg < 1200
                    padding: 0 56px;
                }
                @media(max-width: 991px){ // md < 992
                    padding: 0 40px;
                }
                @media(max-width: 767px){ // sm < 768
                    padding: 0 24px;
                }
                @media(max-width: 575px){ // xs < 576
                    padding: 0 8px;
                }
            }
        }
    }

</style>
