<template>
  <div class="sign-in-up">
    <template v-if="user===undefined">
        <v-container v-if="state.view=='sign_in'">
            <v-row>
                <!-- <v-col cols="3">
                    <Title :language="language" :config="{title: {'en-ca':'Log into your account'}, dense: true}"/>
                </v-col> -->
                <v-col>
                    <form @submit.prevent="signIn">

                        <h3>Welcome back!</h3>
                        Please enter your credentials<br><br>

                        <v-text-field background-color="white" v-model="form.email" label="*E-mail Address" @keydown="rememberMe=false" outlined rounded required :rules="rules.required"/>
                        <v-text-field background-color="white" v-model="form.password" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye'" @keydown.enter="signIn" @click:append="state.showPassword=!state.showPassword" outlined rounded label="*Password" required :rules="rules.required"/>
                        <v-container fluid>
                            <v-row class='flex'>
                                <div class='rememberMe'>
                                    <v-checkbox style="position: relative; top: -15px;" :disabled="!isValid('email',form.email)" v-model="rememberMe" :true-value="true" :false-value="false" label="Remember Me"/>
                                </div>
                                <div class="forgotPassword">
                                    <v-btn text medium @click="state.view='forgotPassword'" style='text-decoration:none; text-transform:none;'>Forgot Password?</v-btn>
                                </div>
                            </v-row>
                        </v-container>
                        <center>
                            <v-btn x-large rounded style="width:100%; background-color:#c45d4a; text-transform:none;" :disabled="!allowSignIn" class="themed-button-dark signInButton" elevation="0" @click="signIn">
                                <v-progress-circular :width="2" :size="25" v-if="queue>0" indeterminate color="primary"/>
                                <str v-else index="authenticator.buttons.sign_in" style='text-transform:none;'/>
                            </v-btn>
                        </center>
                        <!--
                        <Or/>
                        <center>
                            <v-btn x-large rounded style="width:100%; text-transform:none;" class="themed-button-dark otpButton" elevation="0" @click="state.view='otp'">
                                <str index="authenticator.buttons.use_otp" style='text-transform:none;'/>
                            </v-btn>
                        </center>
                        -->
                        <!-- <br><br><br>
                        Need an account?
                        <v-btn class="themed-button-dark" elevation="0" @click="$router.push({path:'/sign_up'})">Sign Up</v-btn> -->
                    </form>
                </v-col>
                <!-- <v-col cols="1" style="text-align: center;'">
                    <div class="vertical-line"/>
                    <div class="vertical-line-text">OR</div>
                    <div class="vertical-line"/>
                </v-col>
                <v-col>
                    <div @click="federation('Google')">
                        <FederationBox style="margin: 15px;" icon="mdi-facebook" text="Sign in with Google" bgColor="#262B76"/>
                    </div>
                    <div @click="federation('Facebook')">
                        <FederationBox style="margin: 15px;" icon="mdi-google" text="Sign in with FaceBook" bgColor="#4C79BD"/>
                    </div>
                </v-col> -->
            </v-row>
        </v-container>

        <v-container v-if="state.view=='otp'">
            <div style="margin: 15px;">
                <str index="authenticator.view.pin.subheader"/>
            </div>
            <v-text-field v-model="form.email"
                prepend-inner-icon="mdi-account-circle"
                outlined rounded
                required
                :rules="rules.validEmail"
                :label="'Email'"
                @click:append-outer="requestOTP"
            />
                <!-- :append-outer-icon="validations.validEmail(form.email) ? 'mdi-send' : ''" -->
            <center>
                <v-btn rounded :disabled="!validations.validEmail(form.email) || busy" class="themed-button-dark" style='text-transform:none;' elevation="0" @click="requestOTP">
                    <str index="authenticator.buttons.send_otp" style='text-transform:none;'/>
                    <v-icon v-if="!busy" small right>
                        mdi-send
                    </v-icon>
                    <v-icon v-if="busy" right>mdi-spin mdi-loading</v-icon>
                </v-btn>
            </center>
            <v-container v-if="temp.user">
                <v-row>
                    <v-col style="text-align: right;">

                        <center>
                            <str index="authenticator.view.pin.enter_pin"/>
                        </center>
                        <v-otp-input
                            v-model="temp.otp"
                            type="number"
                            :length="config.pinLength"
                        />
                        <v-btn text style='text-transform:none;' :disabled="!validations.validEmail(form.email) || busy" @click="requestOTP">
                            <str index="authenticator.buttons.resend"/>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn x-large rounded style="width:100%; text-transform:none;" color="primary" :disabled="(!allowOTP || busy)" @click="signInOTP">
                            <v-icon left v-if="busy">mdi-spin mdi-loading</v-icon>
                            <str index="authenticator.buttons.sign_in"/>
                        </v-btn>
                    </v-col>
                </v-row>

            </v-container>

            <Or/>
            <center>
                <v-btn x-large rounded style="width:100%; text-transform:none;" class="themed-button-dark" elevation="0" @click="state.view='sign_in'">
                    <str index="authenticator.buttons.use_password" style='text-transform:none;'/>
                </v-btn>
            </center>
        </v-container>

        <v-container v-if="state.view=='forgotPassword'">
            <v-row>
                <v-col>
                    <v-btn text class="themed-button-dark" style='text-transform:none;' elevation="0" @click="state.view='sign_in'">
                        <v-icon>
                            mdi-chevron-left
                        </v-icon>
                        Back
                    </v-btn>
                    <div  style="text-align: center; margin-top: 25px;">
                        <Title :language="language" :config="{title: 'authenticator.view.sign_in.forgot_password'}"/>
                        <br>
                        <form @submit.prevent="signIn">
                            <v-text-field background-color="white" v-model="form.email" label="*E-mail Address" outlined/>
                            <v-btn x-large rounded style="width:100%; text-transform:none;" :disabled="!isValid('email',form.email)" class="themed-button-dark" elevation="0" @click="forgotPassword">Send reset code</v-btn>
                            <Or/>
                            <v-btn x-large rounded style="width:100%; text-transform:none;" :disabled="!isValid('email',form.email)" class="themed-button-dark" elevation="0" @click="state.resetPassword=true">Have a reset code?</v-btn>
                        </form>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="state.view=='sign_up' && check_in_code">
            <v-row>
                <v-col>
                    <Title :language="language" :config="{title: 'authorizer.sign_up.create_your_account', dense: true}"/>
                    <!-- , subtitle: {'en-ca':'Register to pin content for easy access'} -->
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <form @submit.prevent="false">
                        <v-text-field background-color="white" v-model="form.given_name" label="*First Name" style="margin: 5px; display: block;" outlined rounded :rules="rules.required"/>
                        <v-text-field background-color="white" v-model="form.family_name" label="*Last Name" style="margin: 5px; display: block;" outlined rounded :rules="rules.required"/>
                        <v-text-field background-color="white" :disabled="true" v-model="form.email" label="*E-mail Address" style="margin: 5px; display: block;" outlined rounded/>
                        <!-- <div style="width: 48%; display: inline-block;">
                            *I am a Healthcare Professional
                            <v-radio-group row v-model="form.hcp">
                                <v-radio value="Y" label="Yes"/> <v-radio value="N" label="No"/>
                            </v-radio-group>

                        </div>
                        <div style="width: 48%; display: inline-block;">
                            <v-text-field background-color="white" v-model="form.country" label="*Country" outlined/>
                        </div>
                        <v-select background-color="white" label="Specialty" outlined style="width: 48%;"></v-select> -->
                        <v-text-field background-color="white" v-model="form.password" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" @click:append="state.showPassword=!state.showPassword" style="margin: 5px; display: block;" outlined rounded label="*Password" :rules="rules.validPassword">
                            <template v-slot:message="{ message }">
                                <div v-html="message" />
                            </template>
                        </v-text-field>
                        <v-text-field background-color="white" v-model="temp.confirmPassword" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" @click:append="state.showPassword=!state.showPassword" style="margin: 5px; display: block;"  outlined rounded label="*Confirm Password" :rules="rules.verifySignupPasswords">
                            <template v-slot:message="{ message }">
                                <div v-html="message" />
                            </template>
                        </v-text-field>
                        <v-checkbox v-model="form.hcp" true-value="Y" false-value="N" label="By registering, I confirm I am a Canadian healthcare professional and have been invited to visit this site."/>
                        <div style="margin: 15px; text-align: center;">
                            <v-btn :disabled="!allowRegister" style="background-color:#c45d4a; color:white; width:100%; border-radius:100px; text-transform:none;" elevation="0" @click="signUp">Register</v-btn>
                            <Or/>
                            <v-btn style="width:100%; border-radius:100px; text-transform:none;" elevation="0" @click="state.verifySignUp=true">Received a verification code?</v-btn>
                            <br>
                            <br>
                            <v-btn style="width:100%; border-radius:100px; text-transform:none;" elevation="0" @click="$router.push({path:'/sign_in'})">Cancel</v-btn>
                        </div>
                    </form>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="state.verifySignUp" width="600">
            <v-card>
                <v-card-title>
                    Resume Sign Up
                </v-card-title>
                <v-card-text>
                    <form @submit.prevent="false">
                        <v-text-field :disabled="true" v-model="form.email" label="*E-mail Address" outlined/>
                        <v-otp-input
                            :disabled="!allowVerificationPinEntry"
                            v-model="temp.verificationCode"
                            type="number"
                            :length="config.pinLength"
                        />
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="themed-button-dark" style='text-transform:none;' elevation="0" @click="state.verifySignUp=false">Close</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!isValid('email',form.email)" class="themed-button-dark" style='text-transform:none;' elevation="0" @click="resendConfirmationCode">Resend Code</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!allowSignUpVerify" class="themed-button-dark" style='text-transform:none;' elevation="0" @click="verifySignUp">Verify</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="state.resetPassword" width="600">
            <v-card>
                <v-card-title>
                    Reset Password
                </v-card-title>
                <v-card-text>
                    <form @submit.prevent="false">
                        <v-text-field v-model="form.email" label="*E-mail Address" outlined/>
                        <v-text-field v-model="form.password" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" @click:append="state.showPassword=!state.showPassword" style="width: 48%; margin: 5px; display: inline-block;" outlined label="*Password"/>
                        <v-text-field v-model="temp.confirmPassword" :type="state.showPassword ? 'text' : 'password' " :append-icon="state.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" @click:append="state.showPassword=!state.showPassword" style="width: 48%; margin: 5px; display: inline-block;" outlined label="*Confirm Password"/>
                        <str index="authenticator.forgot_password.reset_code"/>
                        <v-otp-input
                            :disabled="!allowVerificationPinEntry"
                            v-model="temp.verificationCode"
                            type="number"
                            :length="config.pinLength"
                        />
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-btn rounded class="themed-button-dark" elevation="0" @click="state.resetPassword=false">Close</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded :disabled="!isValid('email',form.email)" class="themed-button-dark" style='text-transform:none;' elevation="0" @click="forgotPassword">
                        <str index="authenticator.buttons.resend"/>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded :disabled="!allowResetPassword" class="themed-button-dark" style='text-transform:none;' elevation="0" @click="resetPassword">
                        <str index="authenticator.buttons.reset_password"/>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>

    <template v-else>
        Loading Home Page ...
    </template>

    <v-snackbar v-model="state.snackbar.display" rounded="pill">
        <span v-html="state.snackbar.message"/>
    </v-snackbar>
  </div>
</template>

<script>
import validations from '@/plugins/validations.js'
import Title from '@/components/Title/Title'
import cache from '@/plugins/cache.js'
import FederationBox from './FederationBox'
import Or from './assets/or.vue'

export default {
    props: {
        prefill: {
            type: Object,
            required: false
        },
        check_in_code: {
            type: String,
            required: false
        }
    },
    components: {
        Title,
        FederationBox,
        Or
    },
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        let remember_me = cache.storage.local.get('remember_me')
        if(remember_me){
            this.rememberMe = true
            this.form.email = remember_me
        }


        this.state.view = this.path
        if(this.user){
            this.afterLogin()
        }

        if(this.prefill){
            this.applyPrefill()
        }
    },
    data: function(){
        return {
            queue: 0,
            config: {
                pinLength: 6
            },
            validations: null,
            rules: null,
            state: {
                view: 'sign_in',
                verifySignUp: false,
                showPassword: false,
                resetPassword: false,
                snackbar: {
                    display: false,
                    message: null
                }
            },
            rememberMe: false,
            form: {
                email: null,
                given_name: null,
                family_name: null,
                password: null,
                specialty: null,
                country: null,
                hcp: "N",
                language: null
            },
            temp: {
                confirmPassword: null,
                verificationCode: null,
                user: false,
                otp: null
            }
        }
    },
    methods: {
        afterLogin: async function(){
            try {
                let user = this.user
                if(user){
                    this.sendRequest('post','/api/logger', {user_id: this.user.username, action: 'login', param: {email: this.user.attributes.email, hubspot_id: this.user.attributes['custom:hubspot_id']}})
                    let pathAfterLogin = cache.storage.local.get('pathAfterLogin')
                    if(pathAfterLogin){
                            await this.$router.push({path:pathAfterLogin});
                            cache.storage.local.clear('pathAfterLogin')
                    }
                    else if (this.$store.getters.profileFormState==='opened') {
                        console.log("Wait for first login profile update")
                    }
                    else {
                        setTimeout(function(){
                            this.$router.push({name:'Home'})
                        }.bind(this), 0)
                    }
                }
            } catch (err) {
                throw new Error(`Problem handling something: ${err}.`);
            }
        },
        federation: async function(provider){
            this.queue++
            console.log('attempting federation signIn',{
                provider
            })
            let response = await this.Authorizer.federation(provider)
            console.log('Federation',{
                provider,
                response
            })
            this.queue--
        },
        signIn: async function(){
            this.queue++
            let data = this.getFields('signIn')
            let response = await this.Authorizer.signIn(data)
            console.log('signIn', response)
            if(response.code=='NotAuthorizedException'){
                this.state.snackbar.display = true
                this.state.snackbar.message = response.message
            }
            this.queue--
        },
        requestOTP: async function(){
            this.queue++
            let response = await this.Authorizer.requestOTP(this.form.email)
            console.log('requestOTP', response)
            this.temp.user = true
            this.queue--
        },
        signInOTP: async function(){
            this.queue++
            let response = await this.Authorizer.signInOTP(this.temp.otp)
            console.log('signInOTP', response)
            this.queue--
        },
        signUp: async function(){
            let fields = this.getFields('signUp')
            let check_in_code = this.check_in_code
            if(check_in_code){
                let email = fields.email
                this.queue++
                let response = await this.sendRequest('post','/api/verify_signup',{check_in_code, email})
                if(response.data.verified){
                    let fields = this.getFields('signUp')
                    let data = {
                        username: fields.email,
                        password: fields.password,
                        attributes: {
                            email: fields.email,
                            given_name: fields.given_name,
                            family_name: fields.family_name,
                            // "custom:country": fields.country,
                            "custom:hcp": fields.hcp,
                            "custom:language": fields.language,
                            // "custom:specialty": fields.specialty
                        },
                        authSignIn: {
                            enabled: true
                        }
                    }
        
                    let response = await this.Authorizer.signUp(data)
                    if(response.event=='signUp'){
                        this.state.snackbar.message = response.message + '<br>A verficiation code has been sent to your email (please check your junk folder)'
                        this.state.verifySignUp = true
                    }
                    if(response.event=='signUp_failure'){
                        this.state.snackbar.message = response.message + "<br>Have you already signed up?  Check your inbox/junk folder for the verfication code"
                    }
        
                }else{
                    this.state.snackbar.message = "Invalid email or invite link"
                }
                this.state.snackbar.display = true
                this.queue--
            }
        },
        resendConfirmationCode: async function(){
            this.queue++
            let username = this.form.email
            let response = await this.Authorizer.resendConfirmationCode(username)
            this.state.snackbar.message = 'A verficiation code has been sent to your email (please check your junk folder)'
            this.state.snackbar.display = true
            console.log('resendConfirmationCode', response)
            this.queue--
        },
        forgotPassword: async function(){
            this.queue++
            let username = this.form.email
            let response = await this.Authorizer.forgotPassword(username)
            this.state.snackbar.message = 'A reset code has been sent to your email (please check your junk folder)'
            this.state.snackbar.display = true
            console.log('sendResetCode', response)
            this.queue--
        },
        resetPassword: async function(){
            this.queue++
            let username = this.form.email
            let password = this.form.password
            let verificationCode = this.temp.verificationCode
            let response = await this.Authorizer.resetPassword(username, verificationCode, password)
            if(response=='SUCCESS'){
                this.state.snackbar.display = true
                this.state.snackbar.message = 'Password reset successful'
                this.state.view=='sign_in'
            }
            console.log('sendResetCode', response)
            this.queue--
        },
        verifySignUp: async function(){
            this.queue++
            let form = this.form
            let username = form.email
            let verificationCode = this.temp.verificationCode
            let response = await this.Authorizer.confirmSignUp({username, verificationCode})
            if(response.event =='confirmSignUp'){
                this.state.snackbar.message = response.message
                this.state.verifySignUp = false
                this.state.snackbar.display = true
                this.$router.push({path:'/sign_in'})
            }

            console.log('verifySignUp', response)
            this.queue--
        },
        getFields: function(formType){
            let language = this.language
            let form = this.form
            form.language= language
            if(formType=='signIn'){
                return {
                    email: form.email,
                    password: form.password
                }
            }else if(formType=='signUp'){
                return form
            }
        },
        isValid: function(type, input){
            if(input===null){
                return false
            }

            if(type=='email'){
                return this.validations.validEmail(input)
            }

            if(type=='password'){
                return this.validations.validPassword(input)
            }

            if(type=='hcp'){
                return input.length==1
            }

            if(type=='country'){
                return input.length>=2
            }

            if(type=='verificationCode'){
                return input.length==this.config.pinLength
            }
        },
        applyPrefill(){
            let prefill = this.prefill
            this.rememberMe = false
            for(let key in prefill){
                this.form[key] = prefill[key]
            }            
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        path: function(){
            let path = this.$route.path.split('/')
            return path[1]
        },
        allowSignIn: function(){
            let form = this.form
            return this.isValid('email', form.email) && this.isValid('password', form.password) && !this.busy
        },
        allowRegister: function(){
            let form = this.form
            let confirmPassword = this.temp.confirmPassword
            let tests = {
                validEmail: this.isValid('email', form.email),
                validPassword: this.isValid('password', form.password),
                validConfirmPassword: this.isValid('password', confirmPassword),
                matching: form.password==confirmPassword,
                hcp: form.hcp=='Y'
                // hcp: this.isValid('hcp', form.hcp),
                // country: this.isValid('country', form.country)
            }

            function allTrue(object){
                for(let key in object){
                    if(!object[key]){
                        return false
                    }
                }
                return true
            }
            return allTrue(tests)
        },
        allowVerificationPinEntry: function(){
            let form = this.form
            return this.isValid('email', form.email)
        },
        allowSignUpVerify: function(){
            let form = this.form
            let temp = this.temp
            return this.isValid('email', form.email) && this.isValid('verificationCode', temp.verificationCode)
        },
        allowResetPassword: function(){
            let form = this.form
            let temp = this.temp
            return this.isValid('email', form.email) && this.isValid('verificationCode', temp.verificationCode)
        },
        allowOTP: function(){
            return this.temp.otp && this.temp.otp.length==this.config.pinLength
        },
        Authorizer: function(){
            return this.$store.getters.Authorizer
        },
        user: function(){
            return this.$store.getters.user
        },
        busy: function(){
            return this.queue>0
        },
        profileFormFistLoginUpdateState: function(){
            return this.$store.getters.profileFormFistLoginUpdateState
        }
    },
    watch: {
        profileFormFistLoginUpdateState: function(after, before){
            return after==='finished' && before==='in-process' ? this.$router.push({name:'Home'}) : null;         
        },
        queue: function(){
            if(this.queue<0){
                this.queue=0
            }
        },
        path: function(){
            this.state.view = this.path
        },
        user: function(after, before){
            if(after && before===undefined){
                this.afterLogin()
            }
        },
        rememberMe: function(){
            let rememberMe = this.rememberMe
            if(rememberMe){
                cache.storage.local.set('remember_me', this.form.email)
            }else{
                cache.storage.local.clear('remember_me')
            }
        },
        prefill: function(){
            let prefill = this.prefill
            if(prefill){
                this.applyPrefill()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.sign-in-up{

    .flex {
        margin-top: -8px;
        margin-bottom: 8px;
        justify-content: space-around !important;

        @media(max-width: 991px){ // md < 992
            flex-flow: column;
            align-items: center;
        }

        .rememberMe,
        .forgotPassword {
            padding: 0;
        }

        .rememberMe {
            height: 40px;
        }

        .forgotPassword {
            padding: 0;
            text-align: left;

            > button {
                padding: 0;
            }
        }
    }

    .signInButton:not([disabled]) {
        background-color: $orange;
        color: white !important;
    }

    .otpButton {
        background-color: $grey_dark;
        color: white;
    }
}

.vertical-line{
    display: block;
    width: 1px;
    height: 30%;
    border: 1px solid black;
    margin: 0 auto;
}

.vertical-line-text{
    font-size: 1.8em;
    margin: 15px;
}
</style>
